import React from 'react';
import PropTypes from 'prop-types';
import { StyledHr } from './divider.css';

const Divider = ({ spacing }) => (
  <StyledHr
    spacing={spacing}
    style={{ marginLeft: '-2.5rem', width: 'calc(100% + 5rem)' }}
  />
);

Divider.propTypes = {
  spacing: PropTypes.string,
};

export default Divider;
