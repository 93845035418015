import nanostyled from 'nanostyled';

export const StyledHr = nanostyled('hr', {
  spacing: 'my-10 xl:my-14',
  base: 'border-0 border-b',
});

// import styled from 'styled-components';
// import MEDIA from 'helpers/mediaTemplates';

// export const StyledHr = styled.hr`
//   margin-left: -2.25rem;
//   width: calc(100% + 4.5rem);
//   ${MEDIA.DESKTOP`
//     margin-left: 0;
//     width: 100%;
//   `}
// `;
